.App {
  text-align: center;
  background-color: #A2202C;
  background-image: url("bglogo.png");
  background-repeat: no-repeat;
  background-position: 50% 90%;
  background-size: 170px;
}

.App-content {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  color: #fffd;
  transition: .25s;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
  padding-bottom: 40px;
}

.App-link {
  color: #fff;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.App-progress {
  background: #fff;
  border-radius: 20px;
  height: 28px;
  width: 100%;
  padding: 4px;
}

.App-progress div {
  display: block;
  background: #f75050;
  width: 50%;
  height: 100%;
  border-radius: 18px;
  transition: .9s;
}