body {
  margin: 0;
  font-family: Itim, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden!important;
}

button {
  font-family: Itim, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: white;
  border: 2px solid #9c1f2d;
  color: #9c1f2d;
  transition: .25s;
  width: 100%;
  max-width: 90vw;

}

p {
  margin-left: 5vw;
  margin-right: 5vw;
}

a{
  transition: .25s;
}
a:hover, a:active, button:hover, button:active {
  transform: scale(1.1);
}

a:active, button:active {
  opacity: .8;
  transform: scale(1.125);
}